<template>
  <v-container fluid>
    <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title class="pa-4 accent">
          <v-btn :to="{ path: '/usuarios' }" small exact fab text class="mr-2">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-icon color="primary" left>mdi-account-group-outline</v-icon>
          Adicionar Usuario
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <h1 class="pa-4 text-h5 font-weight-bold">Dados Gerais</h1>

          <v-divider class="mb-6"></v-divider>
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="avatar">
                  <v-img :src="avatar" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="info"> mdi-image-plus </v-icon>
                      <h3 class="text-subtitle-1">Arrastar e soltar</h3>
                      <div class="text-caption">Clique para selecionar</div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="9" lg="10">
              <v-row dense class="px-4">
                <!-- Tipo Usuario -->
                <v-col class="" cols="12" md="6">
                  <v-combobox
                    v-model="selectedRole"
                    label="Tipo Usuário"
                    :items="roles"
                    dense
                    :rules="formRules"
                    required
                    item-text="descricao"
                    item-value="id"
                  ></v-combobox>
                </v-col>

                <!-- Nome -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="usuario.nome"
                    label="Nome"
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Email -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.email"
                    label="Email"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <!--Documento-->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.documento"
                    label="Documento"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Celular -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.celular"
                    label="Celular"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Senha -->
                <v-col class="" cols="12" md="6">
                  <v-text-field
                    v-model="usuario.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    name="senha"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    label="Senha"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Confirmar Senha -->
                <v-col class="" cols="12" md="6">
                  <v-text-field
                    v-model="usuario.password_confirmation"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    name="confirmarSenha"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    label="Confirmar Senha"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="selectedStaffVendedor"
                    label="Vendedor"
                    :items="staffVendedores"
                    dense
                    item-text="nome"
                    item-value="id"
                    return-object
                  ></v-combobox>
                </v-col>

                <v-col cols="12" md="4">
                  <!--Apelido-->
                  <v-text-field
                    v-model="usuario.apelido"
                    label="Apelido"
                    dense
                  ></v-text-field>
                </v-col>

                <!--Data de nascimento -->
                <v-col cols="12" md="4">
                  <DataField
                    label="Data Nascimento"
                    :data_sync.sync="usuario.data_nascimento"
                  />
                </v-col>

                <!--Genero -->
                <v-col cols="12" md="2" class="pr-4">
                  <v-select
                    label="Gênero"
                    :items="generos"
                    item-text="descricao"
                    item-value="id"
                    v-model="usuario.genero"
                    dense
                  >
                  </v-select>
                </v-col>

                <!--Empreendedor-->
                <v-col
                  cols="12"
                  md="3"
                  lg="2"
                  class="d-flex flex-column justify-center align-start"
                >
                  <v-checkbox
                    v-model="usuario.empreendedor"
                    label="Empreendedor"
                  ></v-checkbox>
                  <!-- <span>Empreendedor</span> -->
                  <!-- <v-btn-toggle v-model="usuario.empreendedor">
                            <v-btn :value="true" height="40px"> sim </v-btn>
                            <v-btn :value="false" height="40px"> Não </v-btn>
                          </v-btn-toggle> -->
                </v-col>

                <!--XDiamond-->
                <v-col
                  cols="12"
                  md="3"
                  lg="2"
                  class="d-flex flex-column justify-center align-start"
                >
                  <v-checkbox
                    v-model="usuario.xdiamond"
                    label="XDiamond"
                  ></v-checkbox>
                </v-col>

                <!--VIP-->
                <v-col
                  cols="12"
                  md="3"
                  lg="2"
                  class="d-flex flex-column justify-center align-start"
                >
                  <v-checkbox v-model="usuario.vip" label="Vip"></v-checkbox>
                  <!-- <span>Vip</span>
                          <v-btn-toggle v-model="usuario.vip">
                            <v-btn :value="true" height="40px"> sim </v-btn>
                            <v-btn :value="false" height="40px"> Não </v-btn>
                          </v-btn-toggle> -->
                </v-col>
                <!--VIP-->
                <v-col
                  cols="12"
                  md="3"
                  lg="2"
                  class="d-flex flex-column justify-center align-start"
                >
                  <v-checkbox v-model="usuario.tes" label="Tes"></v-checkbox>
                  <!-- <span>Vip</span>
                          <v-btn-toggle v-model="usuario.vip">
                            <v-btn :value="true" height="40px"> sim </v-btn>
                            <v-btn :value="false" height="40px"> Não </v-btn>
                          </v-btn-toggle> -->
                </v-col>

                <!--Maestria-->
                <v-col
                  cols="12"
                  md="3"
                  lg="2"
                  class="d-flex flex-column justify-center align-start"
                >
                  <v-checkbox
                    v-model="usuario.maestria"
                    label="Maestria"
                  ></v-checkbox>
                  <!-- <span>Maestria</span>
                          <v-btn-toggle v-model="usuario.maestria">
                            <v-btn :value="true" height="40px"> sim </v-btn>
                            <v-btn :value="false" height="40px"> Não </v-btn>
                          </v-btn-toggle> -->
                </v-col>

                <!--Classificação-->
                <v-col
                  cols="12"
                  md="5"
                  lg="4"
                  class="d-flex flex-column justify-center align-start"
                >
                  <span class="text-caption">Classificação</span>
                  <!-- <v-btn-toggle group v-model="usuario.classificacao">
                            <v-btn :value="1"> BRONZE </v-btn>
                            <v-btn :value="2"> GOLD </v-btn>
                            <v-btn :value="3"> BLACK </v-btn>
                          </v-btn-toggle> -->

                  <v-radio-group
                    class="mt-0"
                    v-model="usuario.classificacao"
                    row
                  >
                    <v-radio label="BRONZE" :value="1"></v-radio>
                    <v-radio label="GOLD" :value="2"></v-radio>
                    <v-radio label="BLACK" :value="3"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <!-- Botao mais informações -->
              <v-btn
                @click="maisInformacoes = !maisInformacoes"
                :disabled="!validForm"
                color="secondary"
                text
                small
                class="mt-8 mb-4 mx-auto"
              >
                <span v-if="maisInformacoes === false" class="text-button">
                  <v-icon left>mdi-plus</v-icon>
                  Informações Adicionais
                </span>
                <span v-else>
                  <v-icon left>mdi-minus</v-icon>
                  Informações Adicionais
                </span>
              </v-btn>

              <!-- Informações Adicionais -->
              <v-row v-show="maisInformacoes" class="px-4" dense>
                <v-col cols="12" md="4" v-if="usuario.empreendedor == true">
                  <v-text-field
                    label="Qual o segmento do seu Negócio?*"
                    style="width: 500px"
                    class="mobile-size"
                    v-model="usuario.ramo_atividade"
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <v-col v-if="usuario.empreendedor == true" cols="12" md="4">
                  <v-text-field
                    label="Qual o número de funcionários da Empresa?*"
                    v-model="usuario.qtde_funcionarios"
                    required
                    type="number"
                    :rules="formRules"
                    style="width: 500px"
                    class="mobile-size"
                  ></v-text-field>
                </v-col>

                <v-col v-if="usuario.empreendedor == true" cols="12" md="4">
                  <v-text-field
                    label="Por que você resolveu participar do X-Bussines?*"
                    style="width: 500px"
                    class="mobile-size"
                    v-model="usuario.motivo_participacao"
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <v-col v-if="usuario.empreendedor == true" cols="12" md="4">
                  <v-textarea
                    label="Qual a maior dificuldade que você tem hoje para fazer o seu Negócio crescer ou atingir suas metas?*"
                    v-model="usuario.empresa_dificuldade"
                    required
                    rows="12"
                    :rules="formRules"
                    style="width: 500px"
                    class="mobile-size"
                  ></v-textarea>
                </v-col>

                <v-col v-if="usuario.empreendedor == true" cols="12" md="4">
                  <v-textarea
                    label="Qual o seu maior desejo quanto ao seu Negócio? Para onde você quer levar ele? (Detalhe o máximo possível)*"
                    v-model="usuario.desejo_empresa"
                    required
                    rows="12"
                    :rules="formRules"
                    style="width: 500px"
                    class="mobile-size"
                  ></v-textarea>
                </v-col>

                <v-col v-if="usuario.empreendedor == true" cols="12" md="4">
                  <span
                    >Para que eu possa entender e te ajudar melhor no nosso
                    Evento eu preciso saber o tamanho do seu Negócio. Escolha
                    nas alternativas abaixo um intervalo que corresponda ao
                    tamanho do seu Negócio.*</span
                  >
                  <v-radio-group v-model="usuario.renda_anual" mandatory>
                    <v-radio
                      label="Menos de R$ 100 Mil de Faturamento Anual"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="De R$ 100 mIl a R$ 250 Mil de Faturamento Anual"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="De R$ 250 mIl a R$ 500 Mil de Faturamento Anual"
                      value="3"
                    ></v-radio>
                    <v-radio
                      label="De R$ 500 mil a R$ 1 Milhão de Faturamento anual"
                      value="4"
                    ></v-radio>
                    <v-radio
                      label="De R$ 1 Milhão a R$ 5 Milhões de Faturamento anual"
                      value="5"
                    ></v-radio>
                    <v-radio
                      label="Maior que R$ 5 Milhões de Faturamento anual"
                      value="6"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <!--Instagram-->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="usuario.instagram"
                    label="Instagram"
                    dense
                  ></v-text-field>
                </v-col>

                <!--Site-->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="usuario.site"
                    label="site"
                    dense
                  ></v-text-field>
                </v-col>

                <!--Observação-->
                <v-col cols="12">
                  <v-textarea
                    v-model="usuario.obs"
                    label="Observação"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-btn text :to="{ path: '/usuarios' }" exact color="primary">
            <v-icon class="mr-2" left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="createUsuario"
            :disabled="!validForm"
            color="xbColor"
            class="white--text"
          >
            <v-icon left>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { postUsuario } from "@/api/admin/usuarios.js";
import { allStaffVendedores } from "@/api/vendedores.js";
import { all as fetchRoles } from "@/api/admin/roles.js";
import { inputRequired } from "@/plugins/utils.js";

export default {
  name: "NovoUsuario",

  data() {
    return {
      usuario: {
        status: 1,
        empreendedor: 0,
      },
      avatar: null,
      selectedStaffVendedor: null,
      staffVendedores: [],
      avatarUpload: null,
      loading: false,
      hoverFile: false,
      validForm: true,
      formRules: [inputRequired],
      maisInformacoes: false,
      show: false,
      roles: [],
      loadingRoles: false,
      selectedRole: null,
      loadingStaffs: true,
      generos: [
        {
          id: 0,
          descricao: "Feminino",
        },
        {
          id: 1,
          descricao: "Masculino",
        },
        {
          id: 2,
          descricao: "Prefiro não informar",
        },
      ],
    };
  },

  components: {
    vueDropzone: vue2Dropzone,
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },
  },

  watch: {
    selectedRole() {
      if (this.selectedRole) {
        this.usuario.role_id = this.selectedRole.id;
      }
    },
    selectedStaffVendedor() {
      if (this.selectedStaffVendedor) {
        this.usuario.vendedor_id = this.selectedStaffVendedor.id;
      }
    },
  },

  methods: {
    async getUsuarioStaffs() {
      this.loadingStaffs = true;
      await allStaffVendedores()
        .then((response) => {
          this.staffVendedores = response;
          this.selectedStaffVendedor = this.staffVendedores.find(
            (vendedor) => vendedor.id === this.usuario.vendedor_id
          );

          this.loadingStaffs = false;
        })
        .catch(() => {
          this.loadingStaffs = false;
        });
    },
    fileAdded(file) {
      this.avatarUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
      };
    },
    createUsuario() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const usuario = new FormData();

        if (this.avatarUpload) {
          usuario.append("avatar", this.avatarUpload, this.avatarUpload.name);
        }

        let usuario_2 = {};
        usuario_2.email = this.usuario.email;
        usuario_2.role_id = this.usuario.role_id;
        usuario_2.password = this.usuario.password;
        usuario_2.password_confirmation = this.usuario.password_confirmation;
        usuario_2.nome = this.usuario.nome;
        usuario_2.apelido = this.usuario.apelido;
        usuario_2.celular = this.usuario.celular;
        usuario_2.documento = this.usuario.documento;
        usuario_2.empreendedor = this.usuario.empreendedor;
        usuario_2.tempo_empresa_aberta = this.usuario.tempo_empresa_aberta;
        usuario_2.instagram = this.usuario.instagram;
        usuario_2.site = this.usuario.site;
        usuario_2.status = this.usuario.status;
        usuario_2.obs = this.usuario.obs;
        usuario_2.checkin = this.usuario.checkin;
        usuario_2.vip = this.usuario.vip;
        usuario_2.ramo_atividade = this.usuario.ramo_atividade;
        usuario_2.qtde_funcionarios = this.usuario.qtde_funcionarios;
        usuario_2.motivo_participacao = this.usuario.motivo_participacao;
        usuario_2.empresa_dificuldade = this.usuario.empresa_dificuldade;
        usuario_2.desejo_empresa = this.usuario.desejo_empresa;
        usuario_2.renda_anual = this.usuario.renda_anual;
        usuario_2.data_nascimento = this.usuario.data_nascimento;
        usuario_2.genero = this.usuario.genero;
        usuario_2.vendedor_id = this.usuario.vendedor_id;
        usuario_2.maestria = this.usuario.maestria;
        usuario_2.classificacao = this.usuario.classificacao;
        usuario_2.xdiamond = this.usuario.xdiamond;
        usuario_2.tes = this.usuario.tes;

        for (let key in usuario_2) {
          if (
            usuario_2[key] != null &&
            usuario_2[key] != undefined &&
            usuario_2[key] != ""
          ) {
            usuario.append(key, usuario_2[key]);
          }
        }

        postUsuario(usuario)
          .then((response) => {
            if (response.data.success) {
              this.$toast.success("Criado com sucesso");
              this.loading = false;
              this.$router.push({ path: "/usuarios" });
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$toast.error("Não Validou!");
      }
    },

    getRoles() {
      this.loadingRoles = true;
      return fetchRoles()
        .then((response) => {
          this.roles = response;
          this.selectedRole = this.roles[1];
          this.loadingRoles = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingRoles = false;
        });
    },
  },

  async mounted() {
    await this.getRoles();
    await this.getUsuarioStaffs();
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
